import { FunctionalComponent, h, Fragment } from "preact";
import { Route, Router, RouterOnChangeArgs } from "preact-router";

import Home from "../routes/home";
import HotNews from '../routes/hot-news'
import Material from '../routes/material'
import VideoCollector from '../routes/video-collector'
import HotVideos from '../routes/hot-videos'
import VideoTemplates from '../routes/video-templates'
import SmartEditor from '../routes/smart-editor'
import AutoSounds from '../routes/auto-sounds'
import OneStep from '../routes/one-step'
import CloudMaker from '../routes/cloud-maker'
import TeamManager from '../routes/team-manager'
import Deploy from '../routes/deploy'
import NotFoundPage from "../routes/notfound";
import OpenAccess from '../routes/open-access'
import ArticleDetail from '../routes/article-detail'
import VIPCenter from '../routes/vip'
import InvitePlan from '../routes/invite-plan'
import Header from "./header";
import Footer from "./footer"
import Loading from './Loading'

import { articleList } from '../data/articleList'
import AppDownload from '../routes/app-download'

const App: FunctionalComponent = () => {
  let currentUrl: string;
  const handleRoute = (e: RouterOnChangeArgs) => {
    currentUrl = e.url;
  };

  return (
    <Fragment>
      <Header />
      <Router onChange={handleRoute}>
        <Route path="/" component={Home} />
        <Route path="/hot-news" component={HotNews} />
        <Route path="/material" component={Material} />
        <Route path="/video-collector" component={VideoCollector} />
        <Route path="/hot-videos" component={HotVideos} />
        <Route path="/video-templates" component={VideoTemplates} />
        <Route path="/smart-editor" component={SmartEditor} />
        <Route path="/auto-sounds" component={AutoSounds} />
        <Route path="/cloud-maker" component={CloudMaker} />
        <Route path="/one-step" component={OneStep} />
        <Route path="/team-manager" component={TeamManager} />
        <Route path="/deploy" component={Deploy} />
        <Route path="/open-access" component={OpenAccess} />
        <Route path="/vip" component={VIPCenter} />
        <Route path="/invite-plan" component={InvitePlan} />
        <Route path="/app-download" component={AppDownload} />
        {articleList.map(item => {
          return (
            <Route path={'/article-detail/' + item.pk} component={ArticleDetail} pk={item.pk} title={item.title} content={item.content} />
          )
        })}
        <NotFoundPage default />
      </Router>
      <Footer />
      <Loading />
    </Fragment>
  );
};

export default App;
